import {LayoutModule} from '@backstage-components/base';
import {css} from '@emotion/react';
import React, {VFC, useRef, useEffect, useMemo, Fragment} from 'react';
import {SchemaType} from './PeacockBackgroundDefinition';
import * as Styled from './PeacockBackground.styled';
import {BackgroundGL} from './BackgroundGL';

export type PeacockBackgroundComponentDefinition = LayoutModule<
  'PeacockBackground',
  SchemaType
>;

export const PeacockBackgroundComponent: VFC<
  PeacockBackgroundComponentDefinition
> = (definition) => {
  const {slotRenderer: Component = () => <Fragment />} = definition;
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const glRef = useRef<BackgroundGL>();

  const renderedChildren = useMemo(() => {
    const {items, ...children} = definition.slots ?? {items: []};
    const components = Object.values(children)
      .flatMap((element) => {
        if (Array.isArray(element)) {
          return element;
        } else if (typeof element !== 'undefined') {
          return [element];
        } else {
          return [];
        }
      })
      .concat(items ?? []);

    return components.map((component) => {
      const componentStyle = `${component.style + ';' || ''}`;
      return (
        <Component
          key={`${component.path.join(':')}:${component.mid}`}
          {...component}
          style={componentStyle}
        />
      );
    });
  }, [Component, definition.slots]);

  useEffect(() => {
    if (!canvasRef.current || !containerRef.current)
      return;

    glRef.current = new BackgroundGL(
      canvasRef.current,
      containerRef.current
    );

    return () => {
      if (!glRef.current) return;

      glRef.current.dispose();
    };
  }, []);
  const styles = css`
    ${definition.style}
    ${definition.props.styleAttr}
  `;

  return (
    <Styled.Wrapper css={styles} className="container">
      <Styled.Canvas ref={canvasRef} />
      <Styled.Container ref={containerRef}>{renderedChildren}</Styled.Container>
    </Styled.Wrapper>
  );
};
