import {DynamicComponent} from '@backstage-components/base';
import {StackLayout} from '@backstage-components/stack-layout';
import {StyleLayout} from '@backstage-components/style-layout';
import {ModalComponent} from '@backstage-components/modal';
import {CarouselComponent} from '@backstage-components/carousel';
import {ContainerLayout} from '@backstage-components/container-layout';
import {PeacockBackgroundComponent} from '@backstage-components/peacock-background';
import {RtcRoomContainerComponent} from '@backstage-components/rtc-room-container';
import {createElement, Fragment, useMemo, VFC} from 'react';
import {
  DynamicLayout,
  isLayout,
  isContent,
} from '../types/dynamicComponent.types';
import ComponentController from './componentController';

export const LayoutController: VFC<DynamicLayout> = (definition) => {
  const props = useMemo(
    () => Object.assign({slotRenderer: SlotRender}, definition),
    [definition]
  );
  switch (props.component) {
    case 'Modal':
      return createElement(ModalComponent, props);
    case 'Carousel':
      return createElement(CarouselComponent, props);
    case 'Container':
      return createElement(ContainerLayout, props);
    case 'Stacked':
      return createElement(StackLayout, props);
    case 'Style':
      return createElement(StyleLayout, props);
    case 'RtcRoomContainer':
      return createElement(RtcRoomContainerComponent, props);
    case 'PeacockBackground':
      return createElement(PeacockBackgroundComponent, props);
    default:
      return <Fragment>{definition.component}</Fragment>;
  }
};

const SlotRender: VFC<DynamicComponent> = (component) => {
  if (isLayout(component)) {
    return <LayoutController {...component} />;
  } else if (isContent(component)) {
    return <ComponentController {...component} />;
  } else {
    return null;
  }
};

export default LayoutController;
