import {LazyQueryResult, useLazyQuery} from '@apollo/client';
import {
  PageFieldsFragment as PageFields,
  QuerySiteDetailsByDomain,
  type SiteDetailsByDomainQuery as SiteForPageListing,
  type SiteDetailsByDomainQueryVariables as SiteForPageListingVariables,
} from '@backstage/attendee-ui-types';
import {DynamicComponent} from '@backstage-components/base';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useShowIdRef} from '../useShowId';
import {buildUpModules} from './moduleTree';
import {useSiteVersionId} from './useSiteVersionId';

type Site = SiteForPageListing['site'];

export type PageData = PageFields & {
  modules: DynamicComponent[];
  structure: XMLDocument;
};

interface PageListingResult
  extends Pick<
    LazyQueryResult<SiteForPageListing, SiteForPageListingVariables>,
    'called' | 'error'
  > {
  data: SiteResult | undefined | null;
}

export interface SiteResult extends Omit<SiteForPageListing, 'site'> {
  site: Omit<Site, 'items'> & {items: PageData[]};
}

export const usePageListings = (
  options: UsePageListingOptions
): PageListingResult => {
  const {domainName} = options;
  // store `setIsLoading` in a Ref so the refetch hook doesn't need to depend on
  // `setIsLoading`
  const setIsLoadingRef = useRef(options.setIsLoading);
  // `data` is set from the `Promise` returned by calling query via apollo
  const [data, setData] = useState<SiteForPageListing | undefined | null>(
    undefined
  );
  const showIdRef = useShowIdRef(
    (data?.site?.shows ?? []).map((s) => s.id).join(','),
    options.pathShowId
  );
  const siteVersionId = useSiteVersionId() ?? null;

  // fetch page data, extract showId and add to context for the Attendee Container
  const [getSitePageListings, {called, error}] = useLazyQuery<
    SiteForPageListing,
    SiteForPageListingVariables
  >(QuerySiteDetailsByDomain, {
    fetchPolicy: 'no-cache',
    context: {showId: showIdRef.current},
  });

  // Update `setIsLoadingRef` if value in `options` changes
  useEffect(() => {
    setIsLoadingRef.current = options.setIsLoading;
  }, [options.setIsLoading]);
  // Update "loading" state when `called` state changes or `data` changes
  useEffect(() => {
    const setIsLoading = setIsLoadingRef.current;
    const isLoading = !called || typeof data === 'undefined';
    setIsLoading(isLoading);
  }, [called, data]);
  // Set up listener for 'AccessCode:success' event and perform initial fetch
  useEffect(() => {
    const setIsLoading = setIsLoadingRef.current;
    // Refetch pages on access token change
    const onAccessCodeSuccess = (): void => {
      const showId = showIdRef.current;
      if (typeof showId === 'undefined' || typeof domainName === 'undefined') {
        // This should never happen, `AccessCode:success` can only happen in
        // response to an access code being verified which requires a `showId`
        // and a `domainName` to be set
        console.warn('showId not set but access code verified, WAT');
        return;
      }
      setIsLoading(true);
      getSitePageListings({
        variables: {domainName, versionId: siteVersionId},
        context: {showId},
      })
        .then((r) => setData(r.data))
        .then(() => setIsLoading(false));
    };
    document.body.addEventListener('AccessCode:success', onAccessCodeSuccess);
    return () => {
      document.body.removeEventListener(
        'AccessCode:success',
        onAccessCodeSuccess
      );
    };
  }, [domainName, getSitePageListings, showIdRef, siteVersionId]);
  // Fetch initial data when `domainName` or `showId` changes. `showId` changing
  // will impact the authorization data sent with the request.
  useEffect(() => {
    if (typeof domainName === 'undefined') {
      return;
    }
    const showId = showIdRef.current;
    const setIsLoading = setIsLoadingRef.current;
    setIsLoading(true);
    getSitePageListings({
      variables: {domainName, versionId: siteVersionId},
      context: {showId},
    })
      .then((r) => setData(r.data))
      .then(() => setIsLoading(false));
  }, [domainName, getSitePageListings, showIdRef, siteVersionId]);
  // Only rebuild site & page data when `data`, `domainName` or `showId` changes
  const resultData = useMemo(() => {
    // no data yet or error fetching data
    if (typeof data === 'undefined' || data === null) {
      return data;
    }
    const showId = showIdRef.current;
    const pageData = data.site.pages.map(
      (page): PageData => buildUpModules({page, showId, domainName})
    );
    const result: SiteResult = {
      site: {
        ...data.site,
        items: pageData,
      },
    };
    return result;
  }, [data, showIdRef, domainName]);

  return {data: resultData, called, error};
};

interface UsePageListingOptions {
  /** DomainName of Site data to be retrieved */
  domainName?: string;
  /** Function called to indicate when the application is in a loading state */
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  /** Show id candidate parsed out from the pathname of the `URL` */
  pathShowId?: string;
}
