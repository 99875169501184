/**
 * Clamps a value between two numbers.
 * For example:
 *   - a value of 105 with min=0 and max=100 would return 100
 *   - a value of -5 with min=0 and max=100 would return 0
 *   - a value of 50 with min=0 and max=100 would return 50
 * @param value | number | the value to clamp
 * @param min | number | the minimum value
 * @param max  | number | the maximum value
 * @returns | number | the clamped value
 */

export const clamp = (value: number, min: number, max: number): number => {
  return Math.max(Math.min(value, max), min);
};
