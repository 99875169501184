import {Module, NextModule} from '@backstage-components/base';
import React from 'react';
import {
  RtcRoomContainerComponent,
  RtcRoomContainerComponentDefinition,
} from './RtcRoomContainerComponent';
import {ComponentDefinition} from './RtcRoomContainerDefinition';

type Kind = RtcRoomContainerComponentDefinition['component'];

type Props = RtcRoomContainerComponentDefinition['props'];

export const RtcRoomContainerModule: Module<Kind, Props> = {
  isContent(_): _ is RtcRoomContainerComponentDefinition {
    return false;
  },
  isLayout(value): value is RtcRoomContainerComponentDefinition {
    return (
      value.component === ComponentDefinition.reactName &&
      value.cid === ComponentDefinition.id
    );
  },
  tryRender(definition) {
    if (RtcRoomContainerModule.isContent(definition)) {
      return React.createElement(RtcRoomContainerComponent, definition);
    } else {
      return NextModule;
    }
  },
};
