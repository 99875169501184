/*
  Simple handler for mouse movement in 2d with smoothing

  Example Usage:
  	import MouseMove2D from "../mouseMove2d";
  	let mouseMove2D = new MouseMove2D(element);

    mouseMove2D.mousePos <- get mousepos in range 0 - 1
    mouseMove2D.mousePosSmooth <- get smoothed mousepos in range 0 - 1

*/

import {Vector2} from 'three';

export class MouseMove2D {
  mousePos: Vector2;
  mousePosSmooth: Vector2;
  smoothing: number;
  element: HTMLElement | Window;

  constructor(element: HTMLElement | Window) {
    this.mousePos = new Vector2(0.5, 0.5);
    this.mousePosSmooth = new Vector2(0.5, 0.5);
    this.smoothing = 0.05;
    this.element = element;

    // Typescript bug: https://github.com/microsoft/TypeScript/issues/46819
    // @ts-expect-error: should not error, but gives type error below
    this.element.addEventListener('pointermove', this.onPointerMove);
    this.update();
  }

  onPointerMove = (event: PointerEvent): void => {
    // handle rollovers
    this.mousePos.x = event.clientX / window.innerWidth;
    this.mousePos.y = 1 - event.clientY / window.innerHeight;
  };

  dispose = (): void => {
    // Typescript bug: https://github.com/microsoft/TypeScript/issues/46819
    // @ts-expect-error: should not error, but gives type error below
    this.element.removeEventListener('pointermove', this.onPointerMove, false);
  };

  update = (): void => {
    requestAnimationFrame(this.update);
    // smoothing
    this.mousePosSmooth.x +=
      (this.mousePos.x - this.mousePosSmooth.x) * this.smoothing;
    this.mousePosSmooth.y +=
      (this.mousePos.y - this.mousePosSmooth.y) * this.smoothing;
  };
}
