import {
  styleAttr,
  styleAttrUi,
  ComponentAdmin,
  OptionalString,
  StringEnum,
  PublishesTo,
  SubscribesTo,
  ModuleCategory,
} from '@backstage-components/base';
import {Static, Type} from '@sinclair/typebox';

export const reactName = 'Carousel';
export const name = reactName;
export const description = reactName;
const category: ModuleCategory = 'layout';

const effectAttr = StringEnum(
  ['slide', 'fade', 'cube', 'coverflow', 'flip', 'creative', 'cards'] as const,
  {
    title: 'Transition Effect',
    // Read more here: https://swiperjs.com/swiper-api#param-effect
    description: 'Transition effect applied when slides change',
    default: 'slide',
  }
);

export const schema = Type.Object({
  speed: Type.Optional(
    Type.Number({
      title: 'Speed',
      description:
        'Duration of transition between slides (in miliseconds). Example: 300',
      default: 300,
    })
  ),
  slidesPerView: Type.Optional(
    Type.Number({
      title: 'Slides Per View',
      description:
        "Number of slides per view (slides visible at the same time on slider's container)",
      default: 1,
    })
  ),
  spaceBetween: Type.Optional(
    Type.Number({
      title: 'Space Between',
      description: 'Distance between slides in pixels',
      default: 30,
    })
  ),
  loop: Type.Optional(
    Type.Boolean({
      title: 'Loop Slides',
      description:
        'Set to true to enable continuous loop mode, hitting next on the last slide will navigate back to the first slide',
      default: true,
    })
  ),
  autoplay: Type.Optional(
    Type.Boolean({
      title: 'Autoplay',
      description: 'Set to true to enable continuous autoplay',
      default: false,
    })
  ),
  pagination: Type.Optional(
    Type.Boolean({
      title: 'Pagination',
      description: 'Set to true to enable clickable slide indicators',
      default: true,
    })
  ),
  navigation: Type.Optional(
    Type.Boolean({
      title: 'Navigation',
      description:
        'Set to true to enable clickable left/right arrow slide navigation',
      default: true,
    })
  ),
  height: Type.Optional(
    Type.Number({
      title: 'Height',
      description:
        'Swiper height (in px). Parameter allows to force Swiper height. Warning: Setting this parameter will make Swiper not responsive',
      default: undefined,
    })
  ),
  // Carousel Colors
  styleOptions: Type.Optional(
    Type.Object(
      {
        themeColor: OptionalString({
          title: 'Theme Color',
          description:
            'The color of navigation arrows and the active pagination indicator',
          default: '#007aff',
        }),
        navigationSize: OptionalString({
          title: 'Navigation Size',
          description:
            'The size of the left and right navigation arrows, in pixels. Example: 44px',
          default: '44px',
        }),
      },
      {title: 'Carousel Styles'}
    )
  ),
  effect: effectAttr,
  styleAttr,
});

export type SchemaType = Static<typeof schema>;

export const uiSchema = {
  ...styleAttrUi,
};

export const defaultFieldData: SchemaType = {
  effect: 'slide',
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  navigation: true,
  pagination: true,
  height: undefined,
};

export const instructions = Type.Union([
  SubscribesTo({
    topic: `${reactName}:slide-change`,
    description: 'Update the current slide index',
    meta: {
      slideIndex: Type.String({
        description: 'The index of the slide you would like to change to',
      }),
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-slide-change`,
    description: 'The carousel slide index has changed',
    meta: {
      slideIndex: Type.String({
        description: 'The index of the slide you would like to change to',
      }),
    },
  }),
]);

export const positionRestrictions = {
  disallowedDescendants: [reactName],
};

export const ComponentDefinition: ComponentAdmin = {
  id: '39a5e331-927f-4d17-8e17-5dc0d3ecb539',
  reactName,
  name,
  slug: reactName,
  description,
  version: 1,
  defaultFieldData,
  slotConfiguration: {},
  schema,
  uiSchema,
  instructions,
  positionRestrictions,
  category,
};
