import type {BroadcastFn} from '@backstage-components/base';
import {useEffect, useState} from 'react';
import {instructions, type Speaker} from './RtcRoomContainerDefinition';
import {
  RoomEvent,
  Kit,
  ConnectionQuality,
  Participant,
  RemoteTrackPublication,
  RemoteParticipant,
  RemoteTrack,
  ConnectionState,
  TrackPublication,
} from '@backstage-components/livekit-client';

export function useRoomEvents(
  maybeKit: Kit | undefined,
  broadcast: BroadcastFn<typeof instructions>
): void {
  useKitEffect(
    (kit) => {
      const broadcastFunc = (participants: Participant[]): void =>
        broadcast({
          type: 'RtcRoomContainer:on-active-speaker-changed',
          meta: {
            participants: participants.map((p) => ({
              sid: p.sid,
              identity: p.identity,
              name: p.name,
              metadata: p.metadata,
              isSpeaking: p.isSpeaking,
              audioLevel: p.audioLevel,
              isLocal: kit?.room
                ? kit?.room?.localParticipant.isSpeaking
                : false,
            })),
          },
        });
      kit?.room?.on(RoomEvent.ActiveSpeakersChanged, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.ActiveSpeakersChanged, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (): void =>
        broadcast({
          type: 'RtcRoomContainer:on-audio-playback-change',
          meta: {},
        });
      kit?.room?.on(RoomEvent.AudioPlaybackStatusChanged, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.AudioPlaybackStatusChanged, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (
        quality: ConnectionQuality,
        participant: Participant
      ): void =>
        broadcast({
          type: 'RtcRoomContainer:on-connection-quality-changed',
          meta: {
            quality,
            participantSid: participant.sid,
          },
        });
      kit?.room?.on(RoomEvent.ConnectionQualityChanged, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.ConnectionQualityChanged, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (state: ConnectionState): void => {
        if (state === ConnectionState.Connected) {
          setTimeout(() => {
            const remoteParticipants =
              kit.room?.participants !== undefined
                ? Array.from(kit.room?.participants.values()).map(
                    (rp: RemoteParticipant) => ({
                      sid: rp.sid,
                    })
                  )
                : [];

            return broadcast({
              type: 'RtcRoomContainer:on-room-connection',
              meta: {
                remoteParticipants,
              },
            });
          }, 10);
        }
      };

      kit?.room?.on(RoomEvent.StateChanged, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.StateChanged, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (): void =>
        broadcast({
          type: 'RtcRoomContainer:on-local-track-published',
          meta: {},
        });
      kit?.room?.on(RoomEvent.LocalTrackPublished, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.LocalTrackPublished, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (): void =>
        broadcast({
          type: 'RtcRoomContainer:on-local-track-unpublished',
          meta: {},
        });
      kit?.room?.on(RoomEvent.LocalTrackUnpublished, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.LocalTrackUnpublished, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (
        remoteTrack: RemoteTrack,
        _: RemoteTrackPublication,
        remoteParticipant: RemoteParticipant
      ): void => {
        if (remoteTrack.sid) {
          broadcast({
            type: 'RtcRoomContainer:on-track-subscribed',
            meta: {
              trackSid: remoteTrack.sid,
              trackSource: remoteTrack.source,
              participantSid: remoteParticipant.sid,
            },
          });
        }
      };
      kit?.room?.on(RoomEvent.TrackSubscribed, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.TrackSubscribed, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (
        remoteTrack: RemoteTrack,
        _: RemoteTrackPublication,
        remoteParticipant: RemoteParticipant
      ): void => {
        if (remoteTrack.sid) {
          broadcast({
            type: 'RtcRoomContainer:on-track-unsubscribed',
            meta: {
              trackSid: remoteTrack.sid,
              trackSource: remoteTrack.source,
              participantSid: remoteParticipant.sid,
            },
          });
        }
      };
      kit?.room?.on(RoomEvent.TrackUnsubscribed, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.TrackUnsubscribed, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (
        remoteTrack: RemoteTrackPublication,
        remoteParticipant: RemoteParticipant
      ): void =>
        broadcast({
          type: 'RtcRoomContainer:on-track-published',
          meta: {
            trackSid: remoteTrack.trackSid,
            trackSource: remoteTrack.source,
            participantSid: remoteParticipant.sid,
          },
        });
      kit?.room?.on(RoomEvent.TrackPublished, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.TrackPublished, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (
        remoteTrack: RemoteTrackPublication,
        remoteParticipant: RemoteParticipant
      ): void =>
        broadcast({
          type: 'RtcRoomContainer:on-track-unpublished',
          meta: {
            trackSid: remoteTrack.trackSid,
            trackSource: remoteTrack.source,
            participantSid: remoteParticipant.sid,
          },
        });
      kit?.room?.on(RoomEvent.TrackUnpublished, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.TrackUnpublished, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (remoteParticipant: RemoteParticipant): void =>
        broadcast({
          type: 'RtcRoomContainer:on-participant-connected',
          meta: {
            remoteParticipant: {
              sid: remoteParticipant.sid,
              audioLevel: remoteParticipant.audioLevel,
              identity: remoteParticipant.identity,
              isSpeaking: remoteParticipant.isSpeaking,
              name: remoteParticipant.name,
            },
          },
        });
      kit?.room?.on(RoomEvent.ParticipantConnected, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.ParticipantConnected, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (remoteParticipant: RemoteParticipant): void =>
        broadcast({
          type: 'RtcRoomContainer:on-participant-disconnected',
          meta: {
            remoteParticipant: {
              sid: remoteParticipant.sid,
              audioLevel: remoteParticipant.audioLevel,
              identity: remoteParticipant.identity,
              isSpeaking: remoteParticipant.isSpeaking,
              name: remoteParticipant.name,
            },
          },
        });
      kit?.room?.on(RoomEvent.ParticipantDisconnected, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.ParticipantDisconnected, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (): void =>
        broadcast({
          type: 'RtcRoomContainer:on-media-devices-error',
          meta: {},
        });
      kit?.room?.on(RoomEvent.MediaDevicesError, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.MediaDevicesError, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (): void =>
        broadcast({
          type: 'RtcRoomContainer:on-media-devices-changed',
          meta: {},
        });
      kit?.room?.on(RoomEvent.MediaDevicesChanged, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.MediaDevicesChanged, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (
        trackPublication: TrackPublication,
        participant: Participant
      ): void => {
        broadcast({
          type: 'RtcRoomContainer:on-track-muted',
          meta: {
            trackSid: trackPublication.trackSid,
            participantSid: participant.sid,
            trackSource: trackPublication.source,
          },
        });
      };
      kit?.room?.on(RoomEvent.TrackMuted, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.TrackMuted, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (
        trackPublication: TrackPublication,
        participant: Participant
      ): void =>
        broadcast({
          type: 'RtcRoomContainer:on-track-unmuted',
          meta: {
            trackSid: trackPublication.trackSid,
            participantSid: participant.sid,
            trackSource: trackPublication.source,
          },
        });

      kit?.room?.on(RoomEvent.TrackUnmuted, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.TrackUnmuted, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (): void =>
        broadcast({
          type: 'RtcRoomContainer:on-state-changed',
          meta: {},
        });

      kit?.room?.on(RoomEvent.StateChanged, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.StateChanged, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
}

type Destructor = () => void;
type KitEffectCallback = (kit: Kit) => void | Destructor;

function useKitEffect(
  effect: KitEffectCallback,
  options: {kit: Kit | undefined; deps: ReadonlyArray<unknown>}
): void {
  const {kit, deps} = options;
  useEffect(() => {
    if (!kit) {
      return;
    }
    return effect(kit);
  }, [deps, effect, kit]);
}

export function useSpeakerChangeBroadcasts(
  broadcast: BroadcastFn<typeof instructions>,
  kit?: Kit
): void {
  const [activeSpeakers, setActiveSpeakers] = useState<Speaker[]>([]);
  useEffect(() => {
    if (!kit) {
      return;
    }
    const onSpeakersChange = (participants: Participant[]): void => {
      const currentSpeakers = participants.map<Speaker>((p) => ({
        sid: p.sid,
        identity: p.identity,
        name: p.name,
        metadata: p.metadata,
        isSpeaking: p.isSpeaking,
        audioLevel: p.audioLevel,
        isLocal: p.sid === kit?.room?.localParticipant.sid,
      }));
      const currentSpeakerIds = currentSpeakers.map((s) => s.sid);
      const previousSpeakerIds = activeSpeakers.map((s) => s.sid);
      const addedSpeakers = currentSpeakers.filter(
        (s) => !previousSpeakerIds.includes(s.sid)
      );
      const droppedSpeakers = activeSpeakers.filter(
        (s) => !currentSpeakerIds.includes(s.sid)
      );
      for (const droppedSpeaker of droppedSpeakers) {
        broadcast({
          type: 'RtcRoomContainer:on-speaker-stop',
          meta: {
            ...droppedSpeaker,
          },
        });
      }
      for (const addedSpeaker of addedSpeakers) {
        broadcast({
          type: 'RtcRoomContainer:on-speaker-start',
          meta: {
            ...addedSpeaker,
          },
        });
      }
      setActiveSpeakers(currentSpeakers);
    };
    kit?.room?.on(RoomEvent.ActiveSpeakersChanged, onSpeakersChange);
    return () => {
      kit?.room?.off(RoomEvent.ActiveSpeakersChanged, onSpeakersChange);
    };
  }, [activeSpeakers, broadcast, kit]);
}
