import {gql} from '@apollo/client';

export const GetSelfQuery = gql`
  query GetSelf($room: String!) {
    self {
      id
      livekitToken(room: $room)
    }
  }
`;
