import {
  styleAttr,
  styleAttrUi,
  animationUi,
  ComponentAdmin,
} from '@backstage-components/base';
import {Static, Type} from '@sinclair/typebox';

export const reactName = 'PeacockBackground';
export const name = reactName;
export const description = reactName;

export const schema = Type.Object({
  styleAttr,
});

export type SchemaType = Static<typeof schema>;

export const uiSchema = {
  ...styleAttrUi,
  ...animationUi,
};

export const defaultFieldData: SchemaType = {};

export const ComponentDefinition: ComponentAdmin = {
  id: 'b612d5aa-e8ae-421f-a911-0e34659147b9',
  reactName,
  name,
  slug: reactName,
  description,
  version: 1,
  defaultFieldData,
  slotConfiguration: {
    items: {maxModules: 100, displayWidth: 12, title: 'Modules'},
  },
  schema,
  uiSchema,
  category: 'custom',
};
