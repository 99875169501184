import {
  orientationAttr,
  styleAttr,
  styleAttrUi,
  ComponentAdmin,
  OptionalString,
  ModuleCategory,
} from '@backstage-components/base';
import {Static, Type} from '@sinclair/typebox';

export const reactName = 'Style';
export const name = reactName;
const category: ModuleCategory = 'layout';

export const defaultFieldData = {
  layout: {
    orientation: 'vertical',
  },
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
};

export const schema = Type.Object({
  layout: Type.Optional(
    Type.Object(
      {
        orientation: orientationAttr,
      },
      {title: 'Layout Settings'}
    )
  ),
  autoLayout: Type.Optional(
    Type.Boolean({
      title: 'Enable Auto Layout',
      description:
        'If true, sets a flex property shorthand on this layout module. False by default',
      default: false,
    })
  ),
  backgroundImage: OptionalString('Background Image'),
  backgroundColor: OptionalString('Background Color'),
  backgroundRepeat: OptionalString({
    title: 'Background Repeat',
    default: defaultFieldData.backgroundRepeat,
  }),
  backgroundAttachment: OptionalString('Background Attachment'),
  backgroundPosition: OptionalString({
    title: 'Background Position',
    default: defaultFieldData.backgroundPosition,
  }),
  backgroundSize: OptionalString({
    title: 'Background Size',
    default: defaultFieldData.backgroundSize,
  }),
  maxWidth: OptionalString('Max Width'),
  minHeight: OptionalString('Min Height'),
  padding: OptionalString('Padding'),
  margin: OptionalString('Margin'),
  styleAttr,
});

export type SchemaType = Static<typeof schema>;

export const uiSchema = {
  ...styleAttrUi,
};

export const ComponentDefinition: ComponentAdmin = {
  id: '5717e019-e6b1-40fd-856c-ca153ec33c48',
  reactName,
  name,
  slug: reactName,
  description: 'Stacked layout with extra styling properties',
  version: 1,
  defaultFieldData,
  slotConfiguration: {
    items: {maxModules: 100, displayWidth: 12, title: 'Modules'},
  },
  schema,
  uiSchema,
  category,
};
