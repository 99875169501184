import {useLazyQuery} from '@apollo/client';
import {useShowId} from '@backstage-components/base';
import {useEffect} from 'react';
import {GetSelf, GetSelfQuery} from './gql';

/**
 * Read the LiveKit token from the `GetSelf` query.
 */
export function useLiveKitToken(room: string): string | undefined {
  const showId = useShowId();
  const [getSelf, {data}] = useLazyQuery<GetSelf>(GetSelfQuery, {
    // Allowing for passing of a room, webrtc module id instead of showId to support multiple webrtc rooms on the same page.
    variables: {room},
  });

  useEffect(() => {
    getSelf({
      context: {showId},
    });
  }, [getSelf, showId]);

  return data?.self?.livekitToken;
}
