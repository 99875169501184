import * as THREE from 'three';
import {ShaderWithUniforms, Uniform} from './types/shader.types';

/*
  minimal custom ShaderPass based off of thespite's wagner

  WARNING:
    this will capture the renderer if not called with render(true) at some point
    to fix call this in render loop
    renderer.setRenderTarget(null);

*/

interface ShaderPassConfig {
  width?: number;
  height?: number;
  format?: number;
  type?: THREE.TextureDataType;
  minFilter?: THREE.TextureFilter;
  magFilter?: THREE.TextureFilter;
  wrapS?: THREE.Wrapping;
  wrapT?: THREE.Wrapping;
}

export class ShaderPass<Uniforms extends Uniform<unknown>> {
  renderer: THREE.WebGLRenderer;
  shader: ShaderWithUniforms<Uniforms>;
  name: string;
  orthoCamera: THREE.OrthographicCamera;
  shaderMat: THREE.ShaderMaterial;
  orthoScene: THREE.Scene;
  fbo: THREE.WebGLRenderTarget;
  orthoQuad: THREE.Mesh;
  texture: THREE.Texture;
  uniforms: Uniforms;
  usesResolution = false;
  usesTime = false;

  constructor(
    renderer: THREE.WebGLRenderer,
    shader: ShaderWithUniforms<Uniforms>,
    {
      width = 1024,
      height = 1024,
      format,
      type,
      minFilter,
      magFilter,
      wrapS,
      wrapT,
    }: ShaderPassConfig = {}
  ) {
    this.renderer = renderer;
    this.shader = shader;
    this.name = shader.name;
    this.shaderMat = new THREE.RawShaderMaterial(shader);

    this.orthoScene = new THREE.Scene();
    this.fbo = new THREE.WebGLRenderTarget(width, height, {
      wrapS: wrapS || THREE.ClampToEdgeWrapping,
      wrapT: wrapT || THREE.ClampToEdgeWrapping,
      minFilter: minFilter || THREE.LinearFilter,
      magFilter: magFilter || THREE.LinearFilter,
      format: format || THREE.RGBAFormat,
      type: type || THREE.UnsignedByteType,
      stencilBuffer: false,
      depthBuffer: false,
    });
    this.orthoCamera = new THREE.OrthographicCamera(
      width / -2,
      width / 2,
      height / 2,
      height / -2,
      0.00001,
      1000
    );
    this.orthoQuad = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(1, 1),
      this.shaderMat
    );
    this.orthoQuad.scale.set(width, height, 1);
    this.orthoScene.add(this.orthoQuad);

    this.texture = this.fbo.texture;

    this.uniforms = shader.uniforms;

    if (this.uniforms) {
      this.usesTime = 'time' in this.uniforms;
      this.usesResolution = 'resolution' in this.uniforms;
    }
  }

  render(final = false, renderFBO = this.fbo): void {
    this.renderer.setRenderTarget(final ? null : renderFBO);
    this.renderer.render(this.orthoScene, this.orthoCamera);
  }

  setSize(width: number, height: number): void {
    this.fbo.setSize(width, height);
    this.orthoQuad.scale.set(width, height, 1);
    this.orthoCamera.left = -width / 2;
    this.orthoCamera.right = width / 2;
    this.orthoCamera.top = height / 2;
    this.orthoCamera.bottom = -height / 2;
    this.orthoCamera.updateProjectionMatrix();
  }
}
