import {Alert, AlertIcon} from '@chakra-ui/react';
import {ModuleNode} from '@backstage-components/base';
import React, {VFC} from 'react';
import {SchemaType} from './PeacockBackgroundDefinition';

export type PeacockBackgroundProps = SchemaType;

export const PeacockBackgroundPreviewComponent: VFC<
  ModuleNode<'PeacockBackground', PeacockBackgroundProps>
> = (definition) => {
  const {slotComponent: Slot} = definition;

  return (
    <div>
      <Alert status="info">
        <AlertIcon />
        Peacock Background: This will render in the guest UI view.
      </Alert>
      <Slot slotName="modules" />
    </div>
  );
};
