import {
  styleAttr,
  styleAttrUi,
  animationStates,
  animationUi,
  ComponentAdmin,
  SubscribesTo,
  animationDescription,
  scrollToDescription,
  ModuleCategory,
} from '@backstage-components/base';
import {Static, Type} from '@sinclair/typebox';

export const reactName = 'Container';
export const name = reactName;
export const description = reactName;
const category: ModuleCategory = 'layout';

export const defaultFieldData = {};

export const schema = Type.Object({
  animationStates,
  styleAttr,
});

export type SchemaType = Static<typeof schema>;

export const uiSchema = {
  ...styleAttrUi,
  ...animationUi,
};

export const instructions = Type.Union([
  SubscribesTo({
    topic: `${reactName}:animationState`,
    description: animationDescription,
    meta: {
      stateName: Type.String(),
    },
  }),
  SubscribesTo({
    topic: `${reactName}:scrollTo`,
    description: scrollToDescription,
    meta: {
      scrollX: Type.Optional(Type.String()),
      scrollY: Type.Optional(Type.String()),
      anchorElId: Type.Optional(Type.String()),
      elementId: Type.Optional(Type.String()),
    },
  }),
]);

export const ComponentDefinition: ComponentAdmin = {
  id: '9b365e21-e2fe-4164-8c05-851342f644fe',
  reactName,
  name,
  slug: reactName,
  description,
  version: 1,
  defaultFieldData,
  slotConfiguration: {},
  schema,
  uiSchema,
  instructions,
  category,
};
