export {PeacockBackgroundComponent} from './PeacockBackgroundComponent';
export type {PeacockBackgroundComponentDefinition} from './PeacockBackgroundComponent';
export {
  defaultFieldData,
  schema,
  uiSchema,
  ComponentDefinition,
} from './PeacockBackgroundDefinition';
export type {SchemaType} from './PeacockBackgroundDefinition';
export {PeacockBackgroundPreviewComponent} from './PeacockBackgroundPreviewComponent';

import {Registry} from '@backstage-components/base';
import {PeacockBackgroundModule} from './PeacockBackgroundModule';
import {ComponentDefinition} from './PeacockBackgroundDefinition';
Registry.register(ComponentDefinition, PeacockBackgroundModule);
