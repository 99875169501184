import type {Static} from '@sinclair/typebox';
import {StringEnum} from './typebox-helpers';

export const orientationAttr = StringEnum(['horizontal', 'vertical'] as const, {
  title: 'Orientation',
  default: 'vertical',
});

export type Orientation = Static<typeof orientationAttr>;

export type Oriented = {
  orientation: Orientation;
};
