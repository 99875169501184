import {
  styleAttr,
  styleAttrUi,
  animationStates,
  animationUi,
  DeriveInstructionType,
  OptionalString,
  SubscribesTo,
  PublishesTo,
  animationDescription,
  StringEnum,
  makeComponentAdmin,
  ModuleCategory,
} from '@backstage-components/base';
import {schema as buttonSchema} from '@backstage-components/button';
import {schema as textInputSchema} from '@backstage-components/text-input';
import {Static, Type} from '@sinclair/typebox';

export const reactName = 'AccessCode';
export const name = 'Access Code';
export const description = reactName;
const category: ModuleCategory = 'preset';

const magicLinkKeySchema = Type.String({
  title: 'Key for magic link',
  description:
    'If magic link is enabled, you can provide your own key for the access code in the URL. Only alphanumeric characters, and underscores are allowed. Defaults to "ac". (e.g. my-site.com/my-page?ac=myAccessCode)',
  format: 'regex',
  pattern: '^[a-zA-Z0-9_]+$',
  default: 'ac',
});

export const schema = Type.Object(
  {
    magicLinkSupport: StringEnum(
      ['none', 'auto-fill', 'auto-submit'] as const,
      {
        default: 'none',
        description:
          '"auto-fill" fills in the code, "auto-submit" fills in the code and submits for verification. For auto-submit to work "Show terms and conditions checkbox" must be unchecked.',
        title: 'How to treat magic links',
      }
    ),
    title: OptionalString({title: 'Title'}),
    subtitle: OptionalString({title: 'Subtitle'}),
    accessCodeLength: Type.Optional(Type.Number({title: 'Access Code Length'})),
    accessCodeErrorMessage: OptionalString({
      title: 'Error message for wrong access code',
      default: 'Incorrect access code provided',
    }),
    showResendLink: Type.Optional(
      Type.Boolean({title: 'Show resend link?', default: false})
    ),
    showTermsCheckbox: Type.Optional(
      Type.Boolean({
        title: 'Show terms and conditions checkbox?',
        default: true,
      })
    ),
    submitButtonProps: Type.Optional(
      Type.Omit(
        buttonSchema,
        [
          'href',
          'borderRadius',
          'borderColor',
          'borderWidth',
          'borderStyle',
          'padding',
          'fontSize',
          'fontFamily',
          'animationStates',
        ],
        {title: 'Submit Button Props'}
      )
    ),
    codeTextInputProps: Type.Optional(
      Type.Omit(textInputSchema, ['name', 'inputType'], {
        title: 'Code Input Props',
      })
    ),
    // props to replicate the terms link/checkbox in accessCode
    termsLinkProps: Type.Optional(
      Type.Object(
        {
          content: Type.String({
            title: 'Label Content',
            default:
              'I agree to the <a href="#" target="_blank">Terms and Policy</a>',
          }),
        },
        {
          title: 'Checkbox Label',
          description:
            'Content for checkbox label that will be parsed by html-react-parser',
        }
      )
    ),
    // props to replicate the resend code button in accessCode
    resendButtonProps: Type.Optional(
      Type.Omit(
        buttonSchema,
        [
          'href',
          'buttonColor',
          'borderColor',
          'borderRadius',
          'borderStyle',
          'borderWidth',
          'fontFamily',
          'fontSize',
          'padding',
          'animationStates',
          'styleAttr',
        ],
        {title: 'Resend Button Props'}
      )
    ),
    animationStates,
    styleAttr,
  },
  {
    dependencies: {
      magicLinkSupport: {
        oneOf: [
          {
            properties: {
              magicLinkSupport: {
                enum: ['auto-fill', 'auto-submit'],
              },
              magicLinkKey: magicLinkKeySchema,
            },
          },
        ],
      },
    },
  }
);

export type SchemaType = Static<typeof schema>;

export const uiSchema = {
  codeTextInputProps: {...styleAttrUi},
  submitButtonProps: {...styleAttrUi},
  showResendLink: {'ui:widget': 'hidden'},
  termsLinkProps: {
    content: {
      'ui:widget': 'modalTextareaWidget',
      'ui:options': {
        rows: 5,
        buttonTitle: 'Content',
        editor: 'TinyMCE',
        /**
         * Hard coding this api key as a stop gap since TinyMCE has other safeguards in place around which domains can be whitelisted to display the rich text editor.
         * Planning to remove this api key as part of https://lcdigital.atlassian.net/browse/DLB-1402
         */
        tinyApiKey: 'l8fcep7l5bmg2v6hregoer7ywop9x0hlf1awtmv002azi7pw',
      },
    },
  },
  subtitle: {
    'ui:widget': 'textarea',
    'ui:options': {
      rows: 5,
    },
  },
  ...styleAttrUi,
  ...animationUi,
  'ui:order': ['magicLinkSupport', 'magicLinkKey', '*'],
};

export const defaultFieldData: SchemaType = {
  magicLinkSupport: 'none',
};

export const AccessCodeInstructionSchema = Type.Union([
  SubscribesTo({
    topic: `${reactName}:success`,
    description: 'Access code was successfully verified',
    meta: {
      attendee: Type.Object({
        id: Type.String({
          description: 'Unique identifier for the verified Attendee',
        }),
        name: Type.String({
          description: 'Name of the attendee, if known',
        }),
        email: Type.Union([Type.Null(), Type.String()], {
          description: 'Attendee email address if known, null otherwise',
        }),
        chatTokens: Type.Array(
          Type.Object({
            token: Type.String({
              description: 'Token used to authenticate with getstream API',
            }),
          })
        ),
      }),
    },
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
  SubscribesTo({
    topic: `${reactName}:failure`,
    description: 'Access code could not be verified',
    meta: {
      reason: OptionalString({
        description: 'Indicates the reason the code could not be verified',
      }),
    },
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
  SubscribesTo({
    topic: `${reactName}:reset`,
    description:
      'Resets the access code component (local only) clearing the form',
  }),
  SubscribesTo({
    topic: `${reactName}:animationState`,
    description: animationDescription,
    meta: {
      stateName: Type.String(),
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-failure`,
    description: 'Indicates an unsuccessful verification has occurred.',
    meta: {
      reason: OptionalString({
        description: 'Indicates the reason the code could not be verified',
      }),
      showId: Type.String({
        description:
          'Unique identifier for the show against which Attendee was unable to be verified.',
      }),
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-success`,
    description: 'Indicates a successful verification has occurred.',
    meta: {
      attendeeId: Type.String({
        description: 'Unique identifier for the verified Attendee',
      }),
      attendeeName: Type.Union([Type.Null(), Type.String()], {
        description: 'Attendee name if available, null otherwise',
      }),
      attendeeEmail: Type.Union([Type.Null(), Type.String()], {
        description: 'Attendee email address if available, null otherwise',
      }),
      showId: Type.String({
        description:
          'Unique identifier for the show against which Attendee was verified.',
      }),
    },
  }),
  PublishesTo({
    topic: `${reactName}:verify`,
    description: 'Requests the given access code be verified for the show',
    meta: {
      accessCode: Type.String({
        description: 'Show access code to be verified',
      }),
      showId: Type.String({
        description:
          'Unique identifier for the show against which access code will be checked',
      }),
    },
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
]);

export type AccessCodeInstruction = DeriveInstructionType<
  typeof AccessCodeInstructionSchema
>;

export const ComponentDefinition = makeComponentAdmin<
  typeof AccessCodeInstructionSchema
>({
  id: 'e3dbcc42-2fbe-44ed-97b9-95d795eceac5',
  reactName,
  name,
  slug: reactName,
  description,
  version: 1,
  defaultFieldData,
  slotConfiguration: {},
  schema,
  uiSchema,
  instructions: AccessCodeInstructionSchema,
  category,
  analyticsInstructionMask: (instruction) => {
    switch (instruction.type) {
      case 'AccessCode:on-success':
        return {
          type: instruction.type,
          meta: {
            about: instruction.meta.about,
            attendeeId: instruction.meta.attendeeId,
            showId: instruction.meta.showId,
          },
        };
      case 'AccessCode:success':
        return {
          type: instruction.type,
          meta: {about: instruction.meta.about},
        };
      case 'AccessCode:verify':
        return {
          type: instruction.type,
          meta: {
            about: instruction.meta.about,
            showId: instruction.meta.showId,
          },
        };
      default:
        return instruction;
    }
  },
});
