import * as THREE from 'three';
import {ringPointShader} from './shaders/ringPointShader';
import circImg from './circ.png';
import colorImg from './colors.png';

const pointsPerRing = 60;
const ringCount = 20;

export class RingPoints {
  group: THREE.Group;
  pointCount: number;
  geom: THREE.BufferGeometry;
  pointsMat: THREE.RawShaderMaterial;
  points: THREE.Points;

  constructor(parent3d: THREE.Object3D) {
    this.group = new THREE.Group();
    parent3d.add(this.group);

    this.pointCount = pointsPerRing * ringCount;

    // Create attributes
    const attribs = {
      position: new Float32Array(this.pointCount * 3),
      index: new Float32Array(this.pointCount),
    };

    // Generate attribs
    for (let i = 0; i < this.pointCount; i++) {
      const vertex = new THREE.Vector3();
      vertex.toArray(attribs.position, i * 3);
      attribs.index[i] = i;
    }

    this.geom = new THREE.BufferGeometry();
    this.geom.setAttribute(
      'position',
      new THREE.BufferAttribute(attribs.position, 3)
    );
    this.geom.setAttribute(
      'index',
      new THREE.BufferAttribute(attribs.index, 1)
    );

    this.pointsMat = new THREE.RawShaderMaterial({
      uniforms: ringPointShader.uniforms,
      vertexShader: ringPointShader.vertexShader,
      fragmentShader: ringPointShader.fragmentShader,
    });
    this.pointsMat.blending = THREE.AdditiveBlending;
    this.pointsMat.depthWrite = false;

    const tl = new THREE.TextureLoader();

    this.pointsMat.uniforms.texture.value = tl.load(circImg);
    this.pointsMat.uniforms.colorMap.value = tl.load(colorImg);

    this.points = new THREE.Points(this.geom, this.pointsMat);
    this.group.add(this.points);

    this.points.frustumCulled = false;

    this.update = this.update.bind(this);
    this.update();
  }

  update(): void {
    requestAnimationFrame(this.update);
    const time = performance.now() / 1000;
    this.pointsMat.uniforms.time.value = time;
  }

  resize(dpr: number): void {
    this.pointsMat.uniforms.scl.value = 1; // scl / 1024;
    this.pointsMat.uniforms.dpr.value = dpr;
  }
}
