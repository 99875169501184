import {useShowInstructions, LayoutModule} from '@backstage-components/base';
import {useSubscription} from 'observable-hooks';
import {useEffect, useMemo, FC} from 'react';
import {
  useNavigate as useRouterNavigate,
  useLocation,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router';
import {reactName, RouterInstructionSchema} from './RouterDefinition';

export type RouterComponentDefinition = LayoutModule<
  typeof reactName,
  RouterContainerProps
>;

/**
 * Creates a `react-router` `Routes` node which also subscribes to `Router`
 * instructions in order to manage page navigation with the site `Instruction`
 * flows.
 */
export const RouterContainer: FC<RouterContainerProps> = (props) => {
  const location = useLocation();
  const navigate = useRouterNavigate();
  const {observable, broadcast} = useShowInstructions(RouterInstructionSchema);

  useSubscription(observable, {
    next: (instruction) => {
      if (instruction.type === 'Router:goto') {
        navigate(`${props.prefix ?? ''}${instruction.meta.path}`);
      }
    },
  });

  const currentPath = useMemo(() => {
    const pathname = props.prefix
      ? location.pathname.replace(props.prefix, '')
      : location.pathname;
    return pathname === '' ? '/' : pathname;
  }, [location.pathname, props.prefix]);

  // broadcast a route change instruction when currentPath changes
  useEffect(() => {
    broadcast({
      type: 'Router:on-navigate',
      meta: {currentPath},
    });
    setTimeout(() =>
      broadcast({
        type: 'Router:on-navigate-done',
        meta: {currentPath},
      })
    );
  }, [broadcast, currentPath]);

  // broadcast on-404-no-match instruction if no routes were matched
  const routes = useMemo(() => {
    return createRoutesFromChildren(props.children);
  }, [props.children]);
  useEffect(() => {
    const matches = matchRoutes(routes, location);
    if (matches?.[0].route.path === '*') {
      broadcast({
        type: 'Router:on-404-no-match',
        meta: {currentPath},
      });
    }
  }, [broadcast, currentPath, routes, location]);

  return <Routes>{props.children}</Routes>;
};

export interface RouterContainerProps {
  /**
   * If provided, `prefix` is prepended to every path before navigation is
   * triggered.
   */
  prefix?: string;
}
