import {
  LocalTrack,
  LocalAudioTrack,
  LocalVideoTrack,
  LocalParticipant,
  MediaDeviceFailure,
  ConnectionQuality,
  Participant,
  Room,
  ConnectionState,
  VideoPresets,
  Track,
  TrackEvent,
  type TrackPublication,
  RoomEvent,
  RoomOptions,
  RemoteParticipant,
  RemoteTrackPublication,
  RemoteTrack,
  LocalTrackPublication,
} from 'livekit-client';

export class Kit {
  room: Room | undefined;
  private endpoint: string | undefined;
  private token: string | undefined;

  constructor(endpoint?: string, token?: string) {
    this.endpoint = endpoint;
    this.token = token;
  }

  setEndpoint(endpoint: string): Kit {
    this.endpoint = endpoint;

    return this;
  }

  setToken(token: string): Kit {
    this.token = token;

    return this;
  }

  createRoom(opts: RoomOptions): Kit {
    this.room = new Room(opts);

    return this;
  }

  async connect(): Promise<void> {
    if (this.room === undefined) {
      throw new Error('Missing RTC Room');
    }
    try {
      if (this.token !== undefined && this.endpoint !== undefined) {
        await this.room?.connect(this.endpoint, this.token, {});
      }
    } catch (err) {
      throw new Error(`Error connecting to room: ${err}`);
    }
  }

  disconnect(): void {
    this.room?.disconnect(true);
  }

  async enableCameraAndMic(): Promise<void> {
    return this.room?.localParticipant.enableCameraAndMicrophone();
  }

  async enableCamera(): Promise<LocalTrackPublication | undefined> {
    return this.room?.localParticipant.setCameraEnabled(true);
  }

  async disableCamera(): Promise<LocalTrackPublication | undefined> {
    return this.room?.localParticipant.setCameraEnabled(false);
  }

  async enableMic(): Promise<LocalTrackPublication | undefined> {
    return this.room?.localParticipant.setMicrophoneEnabled(true);
  }

  async disableMic(): Promise<LocalTrackPublication | undefined> {
    return this.room?.localParticipant.setMicrophoneEnabled(false);
  }

  get isConnected(): boolean {
    return this.room?.state === ConnectionState.Connected;
  }

  // Requires permissions first
  get localVideoTrack(): LocalVideoTrack | undefined {
    return this.room?.localParticipant.getTrack(Track.Source.Camera)
      ?.videoTrack;
  }

  // Requires permissions first.
  get localAudioTrack(): LocalAudioTrack | undefined {
    return this.room?.localParticipant.getTrack(Track.Source.Microphone)
      ?.audioTrack;
  }

  get isCameraEnabled(): boolean {
    return this.room !== undefined
      ? this.room?.localParticipant.isCameraEnabled
      : false;
  }

  get isMicEnabled(): boolean {
    return this.room !== undefined
      ? this.room?.localParticipant.isMicrophoneEnabled
      : false;
  }

  // Populated via the listDevices method.
  async videoDevices(): Promise<MediaDeviceInfo[]> {
    try {
      const videoDevices = await Room.getLocalDevices('videoinput');
      return videoDevices;
    } catch (err) {
      const deviceError = MediaDeviceFailure.getFailure(err);

      throw new Error(`DeviceError: ${deviceError}`);
    }
  }

  // Populated via the listDevices method.
  async audioDevices(): Promise<MediaDeviceInfo[]> {
    try {
      const audioDevices = await Room.getLocalDevices('audioinput');
      return audioDevices;
    } catch (err) {
      const deviceError = MediaDeviceFailure.getFailure(err);

      throw new Error(`DeviceError: ${deviceError}`);
    }
  }

  async switchActiveDevice(
    kind: MediaDeviceKind,
    deviceId: string
  ): Promise<void> {
    try {
      await this.room?.switchActiveDevice(kind, deviceId);
    } catch (err) {
      const deviceError = MediaDeviceFailure.getFailure(err);
      throw new Error(`DeviceError: ${deviceError}`);
    }
  }
}

export {
  type LocalTrack,
  MediaDeviceFailure,
  Track,
  type ConnectionQuality,
  type Participant,
  VideoPresets,
  RoomEvent,
  type RoomOptions,
  type RemoteParticipant,
  ConnectionState,
  RemoteTrackPublication,
  RemoteTrack,
  TrackEvent,
  type TrackPublication,
  LocalTrackPublication,
  LocalParticipant,
};
