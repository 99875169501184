import * as THREE from 'three';
import {ShaderWithUniforms} from '../../types/shader.types';
import vertexShader from '../ortho.vert.glsl';
import fragmentShader from './copy.frag.glsl';

export type CopyUniforms = {
  tDiffuse: THREE.IUniform<THREE.Texture | null>;
};

export const CopyShader: ShaderWithUniforms<CopyUniforms> = {
  name: 'Copy',
  uniforms: {
    tDiffuse: {value: null},
  },
  vertexShader,
  fragmentShader,
};
