import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import {
  assign,
  createMachine,
  send,
  EventObject,
  InvokeCreator,
  DoneInvokeEvent,
} from 'xstate';
import {GetAttendeeQuery, VerifyAccessCodeMutation} from './gql';
import type {
  GetAttendee,
  GetAttendeeVariables,
  VerifyAccessCode,
  VerifyAccessCodeVariables,
} from './gql';
import {
  readAccessToken,
  removeAccessToken,
  updateAccessToken,
} from './attendee-session-token';

const initialInvoke: InvokeCreator<Context, Action, AttendeeResult> = (
  context
) => {
  const initialAccessToken = readAccessToken(context.showId);
  if (initialAccessToken) {
    return fetchAttendee(context);
  } else {
    return Promise.reject(new Error('No stored attendee'));
  }
};

const pendingInvoke: InvokeCreator<Context, Action, AttendeeResult> = (
  context,
  event
) => {
  if (event.type === 'FETCH') {
    return fetchAttendee(context);
  } else if (event.type === 'VERIFY') {
    return verifyAttendee(context, event.meta.showId, event.meta.accessCode);
  } else {
    throw new Error(`Unknown event type ${event.type}`);
  }
};

export const ContainerMachine = createMachine<Context, Action, TypeState>(
  {
    id: 'AttendeeContainer',
    initial: 'init',
    states: {
      init: {
        invoke: {
          id: 'initialize',
          src: initialInvoke,
          onDone: {
            actions: [
              send((context, event: DoneInvokeEvent<AttendeeResult>) => {
                const action: Action = {
                  type: 'FETCH_SUCCESS',
                  meta: {attendee: event.data},
                };
                return action;
              }),
            ],
          },
          onError: {
            target: 'idle',
          },
        },
        on: {
          FETCH_SUCCESS: {
            target: 'success',
            actions: ['updateAttendee'],
          },
        },
      },
      idle: {
        on: {
          FETCH: {
            target: 'pending',
            actions: [assign({about: (_c): undefined => undefined})],
          },
          VERIFY: {
            target: 'pending',
            actions: [assign({about: (c, e) => e.meta.about})],
          },
        },
      },
      pending: {
        invoke: {
          id: 'perform',
          src: pendingInvoke,
          onDone: {
            actions: [
              send((context, event: DoneInvokeEvent<AttendeeResult>) => {
                const action: Action = {
                  type: 'FETCH_SUCCESS',
                  meta: {attendee: event.data},
                };
                return action;
              }),
            ],
          },
          onError: {
            actions: [
              send((context, event) => {
                const action: Action = {
                  type: 'FETCH_FAILURE',
                  meta: {
                    reason: getReason(event.data),
                  },
                };
                return action;
              }),
            ],
          },
        },
        on: {
          FETCH_SUCCESS: {
            target: 'success',
            actions: ['updateAttendee'],
          },
          FETCH_FAILURE: {
            target: 'failure',
            actions: ['updateReason'],
          },
        },
      },
      success: {
        on: {
          VERIFY: {
            target: 'pending',
            actions: [assign({about: (c, e) => e.meta.about})],
          },
        },
      },
      failure: {
        entry: ['clearAttendeeId'],
        on: {
          RESET: 'idle',
          VERIFY: {
            target: 'pending',
            actions: [assign({about: (c, e) => e.meta.about})],
          },
        },
      },
    },
  },
  {
    actions: {
      clearAttendeeId: assign((context, event) => {
        if (event.type === 'FETCH_FAILURE') {
          // Clear from local storage
          removeAccessToken(context.showId);
          // Return `BaseContext`
          const result: BaseContext = {
            about: context.about,
            client: context.client,
            showId: context.showId,
          };
          return result;
        } else {
          return context;
        }
      }),
      updateAttendee: assign((context, event) => {
        if (event.type === 'FETCH_SUCCESS' && event.meta.attendee) {
          const attendee = event.meta.attendee;
          const token = attendee.chatTokens[0].token;
          const sessionToken = attendee.sessionToken;
          // Set session token in local storage
          if (typeof sessionToken === 'string') {
            updateAccessToken(context.showId, sessionToken);
          }
          const result: SuccessContext = {
            about: context.about,
            attendee,
            attendeeTags: event.meta.attendee.attendeeTags,
            client: context.client,
            sessionToken,
            showId: context.showId,
            token,
          };
          return result;
        } else {
          return context;
        }
      }),
      updateReason: assign((context, event) => {
        if (event.type === 'FETCH_FAILURE') {
          const result: FailureContext = {
            about: context.about,
            client: context.client,
            reason: event.meta.reason,
            showId: context.showId,
          };
          return result;
        } else {
          return context;
        }
      }),
    },
  }
);

/**
 * Attempt to fetch an Attendee's data based on the context provided by the
 * client. Thrown errors will be caught by the state machine.
 * @param context - details provided by the client
 * @returns the Attendee's data if found, otherwise, returns null or an error
 * message
 * @throws when errors returned by GraphQL
 * @throws when no data from the `self` query
 */
async function fetchAttendee(context: BaseContext): Promise<Attendee> {
  return context.client
    .query<GetAttendee, GetAttendeeVariables>({
      query: GetAttendeeQuery,
      context: {showId: context.showId},
    })
    .then((result) => {
      const {error, errors, data} = result;
      // if an error message was returned, throw it
      if (typeof error !== 'undefined') throw error;
      // if there are multiple errors returned, throw the message
      else if (typeof errors !== 'undefined')
        throw new Error(errors.map((e) => e.message).join('  '));
      // if the `self` query results didn't return in the result, throw
      else if (!data.self) throw new Error('No data from fetch');
      // otherwise, return the Attendee data model
      else {
        return {
          ...data.self.attendee,
          attendeeTags: data.self.attendeeTags.flatMap((tag) => tag) || [],
          chatTokens: data.self.chatTokens,
        };
      }
    });
}

/**
 * Verify that an Attendee with the context and access code provided exists in a
 * particular show. Thrown errors will be caught by the state machine.
 * @param context - details provided by the browser client
 * @param showId - the id of the show to verify
 * @param accessCode - the access code to verify
 * @returns details about the attendee for the verified access code
 * @throws when errors returned by GraphQL
 * @throws when no data from the `verifyAccessCode` mutation
 */
async function verifyAttendee(
  context: BaseContext,
  showId: string,
  accessCode: string
): Promise<Attendee> {
  return context.client
    .mutate<VerifyAccessCode, VerifyAccessCodeVariables>({
      mutation: VerifyAccessCodeMutation,
      variables: {showId, accessCode},
      context: {showId: context.showId},
    })
    .then((result) => {
      const {errors, data} = result;

      // if an error message was returned, throw it
      if (typeof errors !== 'undefined') {
        throw new Error(errors.map((e) => e.message).join('  '));
      }
      // if no verification data was returned, throw an error
      else if (
        typeof data?.verifyAccessCode.sessionToken === 'undefined' ||
        typeof data?.verifyAccessCode.attendee === 'undefined' ||
        data.verifyAccessCode.sessionToken === null ||
        data.verifyAccessCode.attendee === null
      ) {
        throw new Error('No data from verify.');
      }
      // otherwise, return the attendee's data
      else {
        return {
          ...data.verifyAccessCode.attendee,
          sessionToken: data.verifyAccessCode.sessionToken,
          attendeeTags: data.verifyAccessCode.attendeeTags,
          chatTokens: data.verifyAccessCode.chatTokens,
        };
      }
    })
    .catch((reason) => {
      if (reason instanceof Error && reason.message.includes('has been used')) {
        throw new AccessCodeOverusedError(reason.message);
      }
      throw reason;
    });
}

const getReason = (error: Error): string | undefined => {
  switch (error.constructor) {
    case AccessCodeOverusedError: {
      return 'Access Code has been used too many times';
    }
    default: {
      return undefined;
    }
  }
};

type AttendeeResult =
  | Awaited<ReturnType<typeof fetchAttendee>>
  | Awaited<ReturnType<typeof verifyAttendee>>;

interface BaseContext {
  about?: string;
  client: ApolloClient<NormalizedCacheObject>;
  showId: string;
}

interface FailureContext extends BaseContext {
  reason?: string;
}

interface SuccessContext extends BaseContext {
  attendee: Attendee;
  attendeeTags: string[];
  token?: string;
  sessionToken?: string;
}

type Context = SuccessContext | BaseContext;

type Action =
  | Event<'RESET'>
  | Event<'FETCH'>
  | Event<'VERIFY', {about?: string; showId: string; accessCode: string}>
  | Event<'FETCH_FAILURE', {reason?: string}>
  | Event<'FETCH_SUCCESS', {attendee: Attendee}>;

type TypeState =
  | {value: 'init'; context: BaseContext}
  | {value: 'idle'; context: BaseContext}
  | {value: 'pending'; context: BaseContext}
  | {value: 'success'; context: SuccessContext}
  | {value: 'failure'; context: FailureContext};

/**
 * An `Event` with a specific shape of data (`meta` key) and `type`.
 */
interface Event<
  Kind extends string,
  Data extends Record<string, unknown> = Record<string, never>
> extends EventObject {
  /** @inheritdoc */
  type: Kind;
  /**
   * The shape of Data included with the event, if any.
   */
  meta: Data;
}

/** `self` is an access code model returned from the self schema. Since Self can also
 * return `null`, this can prevent us from targeting its nested properties,
 * therefore we specifically wrap this type in `NonNullable` to enable us to get a
 * snapshot of the `self` model
 */
type Self = GetAttendee['self'];
type AttendeeDetails = NonNullable<Self>['attendee'];

/** The properties pertaining to an Attendee */
export interface AttendeeModel extends AttendeeDetails {
  sessionToken?: string;
  attendeeTags: string[];
  chatTokens: NonNullable<Self>['chatTokens'];
}

/** The Attendee data model, or possibly null if the attendee wasn't found  */
export type Attendee = AttendeeModel;

/**
 * Custom `Error` implementation indicating a configuration issue.
 */
export class AccessCodeOverusedError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly
    // See https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#generated-constructor-code-substitutes-the-return-value-of-super-calls-as-this
    Object.setPrototypeOf(this, AccessCodeOverusedError.prototype);
  }
}
