import * as THREE from 'three';
import {gsap} from 'gsap';
import {MouseMove2D} from './utils/mouseMove2d';
import {clamp} from './utils/clamp';
import {RingPoints} from './RingPoints';
import {Post} from './post';

export class BackgroundGL {
  seed: number;
  canvas: HTMLCanvasElement;
  container: HTMLDivElement;
  renderer: THREE.WebGLRenderer | null;
  camera: THREE.PerspectiveCamera | null;
  scene: THREE.Scene | null;
  mainGroup: THREE.Group;
  ringPoints: RingPoints;
  post: Post;
  mouseMove2D: MouseMove2D;
  disposed: boolean;
  raf: number | null;
  mps: THREE.Vector2;
  center: THREE.Vector2;
  tiltAmt: number;
  domPanAmt: number;
  panAmount: number;

  static createRenderer(
    options?: THREE.WebGLRendererParameters
  ): THREE.WebGLRenderer {
    return new THREE.WebGLRenderer(options);
  }

  constructor(
    canvas: HTMLCanvasElement,
    container: HTMLDivElement,
  ) {
    // Constants
    this.mps = new THREE.Vector2();
    this.center = new THREE.Vector2(0.5, 0.5);
    this.tiltAmt = 0.2;
    this.domPanAmt = 50;
    this.panAmount = 0.5;

    this.seed = parseInt(Math.random().toFixed(3), 10) * 1000;
    this.canvas = canvas;
    this.container = container;

    this.renderer = BackgroundGL.createRenderer({
      alpha: true,
      antialias: true,
      canvas: canvas,
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setClearColor(0x080318);
    this.camera = new THREE.PerspectiveCamera(
      30,
      window.innerWidth / window.innerHeight,
      1,
      50000
    );
    this.camera.position.set(0, 0, 18); // ~10 height VP
    this.scene = new THREE.Scene();

    this.mainGroup = new THREE.Group();
    this.scene.add(this.mainGroup);

    this.raf = null;
    this.disposed = false;

    // ADD POINTS
    this.ringPoints = new RingPoints(this.mainGroup);
    this.post = new Post(this.renderer);

    // resize
    window.addEventListener('resize', this.onWindowResize);
    this.onWindowResize();

    this.mouseMove2D = new MouseMove2D(window);

    // fade in
    gsap.fromTo(
      this.canvas,
      {opacity: 0},
      {
        opacity: 1,
        overwrite: true,
        duration: 2,
        delay: 0.5,
      }
    );

    this.update();
  }

  update = (): void => {
    if (this.disposed || !this.scene || !this.camera) return;

    this.post.update(this.scene, this.camera);

    // mouse move
    this.mps.subVectors(this.mouseMove2D.mousePosSmooth, this.center);
    this.mainGroup.rotation.x = -this.mps.y * this.tiltAmt;
    this.mainGroup.rotation.y = this.mps.x * this.tiltAmt;

    this.mainGroup.position.x = -this.mps.x * this.panAmount;
    this.mainGroup.position.y = -this.mps.y * this.panAmount;

    gsap.set(this.container, {
      x: -this.mps.x * this.domPanAmt,
      y: this.mps.y * this.domPanAmt,
    });

    this.raf = requestAnimationFrame(this.update);
  };

  onWindowResize = (): void => {
    if (!this.camera || !this.renderer) return;

    const screenW = window.innerWidth;
    const screenH = window.innerHeight;
    const aspect = screenW / screenH;
    this.camera.aspect = aspect;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(screenW, screenH);
    const dpr = Math.min(window.devicePixelRatio, 2);
    this.renderer.setPixelRatio(dpr);
    this.post.resize(screenW, screenH, dpr);

    // scale content to width
    const sourceAspect = 1;
    let scl = aspect / sourceAspect;
    scl = clamp(aspect, 0.6, 1);

    // disable height scaling
    scl *= 1024 / screenH;
    this.mainGroup.scale.set(scl, scl, scl);
    this.ringPoints.resize(dpr);
  };

  dispose = (): void => {
    this.disposed = true;

    if (this.raf) {
      cancelAnimationFrame(this.raf);
    }

    // clean up any event listeners etc
    window.removeEventListener('resize', this.onWindowResize);

    this.mouseMove2D.dispose();

    if (this.renderer) this.renderer.dispose();

    this.scene = null;
    this.camera = null;
    this.renderer = null;
  };
}
