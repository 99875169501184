import vertexShader from './ringPoint.vert.glsl';
import fragmentShader from './ringPoint.frag.glsl';
import {ShaderWithUniforms} from '../../types/shader.types';
import {IUniform} from 'three';

type RingPoingUniforms = {
  texture: IUniform<THREE.Texture | null>;
  colorMap: IUniform<THREE.Texture | null>;
  time: IUniform<number>;
  scl: IUniform<number>;
  dpr: IUniform<number>;
  sizeRange: IUniform<number>;
  edgeSize: IUniform<number>;
  colorScale: IUniform<number>;
  sizeSpeed: IUniform<number>;
  colorSpeed: IUniform<number>;
};

export const ringPointShader: ShaderWithUniforms<RingPoingUniforms> = {
  name: 'RingLights',
  uniforms: {
    texture: {value: null},
    colorMap: {value: null},
    time: {value: 0},
    scl: {value: 1},
    dpr: {value: 0},
    sizeRange: {value: 1.3},
    edgeSize: {value: 1.3},
    colorScale: {value: 59},
    sizeSpeed: {value: 2},
    colorSpeed: {value: 0.13},
  },
  vertexShader,
  fragmentShader,
};
