import * as THREE from 'three';
import {ShaderWithUniforms} from '../../types/shader.types';
import vertexShader from '../ortho.vert.glsl';
import fragmentShader from './vignette.frag.glsl';

export type VignetteUniforms = {
  tDiffuse: THREE.IUniform<THREE.Texture | null>;
  feather: THREE.IUniform<number>;
  roundness: THREE.IUniform<number>;
  inset: THREE.IUniform<number>;
  brightness: THREE.IUniform<number>;
};

export const VignetteShader: ShaderWithUniforms<VignetteUniforms> = {
  name: 'Vignette',
  uniforms: {
    tDiffuse: {value: null},
    feather: {value: 0.5},
    roundness: {value: 0.5},
    inset: {value: 0.5},
    brightness: {value: 0.0},
  },
  vertexShader,
  fragmentShader,
};
