import {FALLBACK_PAGE_PATH} from '@backstage-components/base';
import {RouterContainer} from '@backstage-components/router';
import {VFC, useEffect, SetStateAction} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {config} from './config';
import {AppContext} from './AppContext';
import {PageRoute} from './PageRoute';

import {ContactLcdFallback} from './components/ContactLcdFallback';
import {FourOhFourLcdFallback} from './components/FourOhFourLcdFallback';
import {
  useShowIdRef,
  usePageListings,
  useDomainName,
  useRouterPrefixes,
} from './hooks';

interface AppProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
}

const App: VFC<AppProps> = ({isLoading, setIsLoading}) => {
  const {domainName, isPreview, showId: pathShowId} = useDomainName() ?? {};
  const {data} = usePageListings({domainName, setIsLoading, pathShowId});

  const site = data?.site;
  const flow = site?.flows[0];
  //Verify show id in url
  const showIdRef = useShowIdRef(
    (data?.site?.shows ?? []).map((s) => s.id).join(','),
    pathShowId
  );

  const {
    router: prefix,
    withShowId: prefixWithShowId,
    withoutShowId: prefixWithoutShowId,
  } = useRouterPrefixes({
    domainName,
    isPreview,
    pathShowId,
    showId: showIdRef.current,
  });
  const domainRecord = site?.domains.find((d) => d.name === domainName);

  useEffect(() => {
    if (flow) {
      localStorage.setItem(
        'flows',
        JSON.stringify(Object.assign({id: flow.id}, flow.data), null, 2)
      );
    }
  }, [flow]);

  if (typeof domainName === 'undefined' || domainName === '') {
    // No domain found
    return (
      <AppContext appPages={[]} domainName={domainName} showId={undefined}>
        <ContactLcdFallback />
      </AppContext>
    );
  } else if (typeof site === 'undefined' && config.stage === 'developer') {
    // Site not found
    return (
      <AppContext appPages={[]} domainName={domainName} showId={undefined}>
        <FourOhFourLcdFallback
          message={`Site Not Found for Domain(${domainName})`}
        />
      </AppContext>
    );
  } else if (
    typeof domainRecord === 'undefined' &&
    config.stage === 'developer'
  ) {
    // No pages on domain
    return (
      <AppContext appPages={[]} domainName={domainName} showId={undefined}>
        <FourOhFourLcdFallback
          message={`No Pages found for Domain(${domainName})`}
        />
      </AppContext>
    );
  } else if (!isLoading) {
    // `Route` needs to be a direct child of `Switch`. `PageRoute` cannot
    // encapsulate `Route` so assemble the `Route` and `PageRoute` elements in
    // multiple passes
    const pages = site?.items ?? [];
    const {FallbackComponent, pageRoutes} = pages.reduce<ProcessedPages>(
      ({FallbackComponent, pageRoutes}, item) => {
        const component = <PageRoute showId={showIdRef.current} page={item} />;
        if (item.pathname === FALLBACK_PAGE_PATH) {
          return {FallbackComponent: component, pageRoutes};
        } else {
          return {
            FallbackComponent: FallbackComponent,
            pageRoutes: pageRoutes.concat({pathname: item.pathname, component}),
          };
        }
      },
      {FallbackComponent: <FourOhFourLcdFallback />, pageRoutes: []}
    );
    const appPages = pages.map((item) => ({
      pathname: item.pathname,
      structure: item.structure,
    }));
    return (
      <AppContext
        appPages={appPages}
        domainName={domainName}
        showId={showIdRef.current}
      >
        <Router>
          <RouterContainer prefix={prefix}>
            {pageRoutes.map((pageRoute) => {
              const pathWithoutShow = `${prefixWithoutShowId}${pageRoute.pathname}`;
              return (
                <Route
                  key={pathWithoutShow}
                  path={pathWithoutShow}
                  element={pageRoute.component}
                />
              );
            })}
            {pageRoutes.map((pageRoute) => {
              const pathWithShow = `${prefixWithShowId}${pageRoute.pathname}`;
              return (
                <Route
                  key={pathWithShow}
                  path={pathWithShow}
                  element={pageRoute.component}
                />
              );
            })}
            <Route element={FallbackComponent} path="*" />
          </RouterContainer>
        </Router>
      </AppContext>
    );
  } else {
    return null;
  }
};

export default App;

interface ProcessedPages {
  /** Component to use as the fallback route */
  FallbackComponent: JSX.Element;
  /** Data to be rendered as a `Route` */
  pageRoutes: ProcessedPageRoute[];
}

interface ProcessedPageRoute {
  /** URL pathname where `component` will be displayed */
  pathname: string;
  /** `PageRoute` component to be displayed for `pathname` */
  component: JSX.Element;
}
