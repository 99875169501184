import * as THREE from 'three';
import {ShaderPass} from './shaderPass';
import {CopyUniforms, CopyShader} from './shaders/copyShader';
import {VignetteShader} from './shaders/vingetteShader';
import {GrainShader} from './shaders/grainShader';
import {Uniform} from './types/shader.types';

export class Post {
  seed: number;
  renderer: THREE.WebGLRenderer;
  renderFBO: THREE.WebGLRenderTarget;
  passes: ShaderPass<Uniform<unknown>>[];
  lastOut: THREE.Texture | null;
  screenPass: ShaderPass<CopyUniforms>;

  constructor(_renderer: THREE.WebGLRenderer) {
    this.seed = Math.random() * 99;
    this.renderer = _renderer;
    this.renderFBO = new THREE.WebGLRenderTarget(256, 256, {
      format: THREE.RGBAFormat,
    });

    this.passes = [];
    this.lastOut = null;

    this.screenPass = new ShaderPass(this.renderer, CopyShader);
    const vp = this.addPass(new ShaderPass(this.renderer, VignetteShader));
    vp.uniforms.feather.value = 0.23;
    vp.uniforms.roundness.value = 0.6;
    vp.uniforms.inset.value = 0.07;

    const gp = this.addPass(new ShaderPass(this.renderer, GrainShader));
    gp.uniforms.amount.value = 0.1;

    this.screenPass.uniforms.tDiffuse.value = this.lastOut;
  }

  addPass = <SpecifiedUniforms extends Uniform<unknown>>(
    pass: ShaderPass<SpecifiedUniforms>
  ): ShaderPass<SpecifiedUniforms> => {
    // chain inputs
    const inTexture = this.lastOut || this.renderFBO.texture;
    pass.uniforms.tDiffuse.value = inTexture;
    this.lastOut = pass.texture;
    this.passes.push(pass);
    return pass;
  };

  resize = (w: number, h: number, dpr: number): void => {
    w = w * dpr;
    h = h * dpr;
    this.renderFBO.setSize(w, h);
    this.screenPass.setSize(w, h);
    this.passes.forEach((pass) => {
      pass.setSize(w, h);
    });
  };

  update = (scene: THREE.Scene, camera: THREE.Camera): void => {
    this.renderer.setRenderTarget(this.renderFBO);
    this.renderer.render(scene, camera);
    const time = performance.now() / 1000 + this.seed;
    this.passes.forEach((pass) => {
      if (pass.usesTime) pass.uniforms.time.value = time;
      pass.render();
    });
    this.screenPass.render(true);
  };
}
