import styled from '@emotion/styled';

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
`;

export const Canvas = styled.canvas`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
`;
