import {DynamicComponent} from '@backstage-components/base';
import {ButtonComponentDefinition} from '@backstage-components/button';
import {CarouselComponentDefinition} from '@backstage-components/carousel';
import {CnnQuizComponentDefinition} from '@backstage-components/cnn-quiz';
import {CnnxLandingComponentDefinition} from '@backstage-components/cnnx-landing';
import {CheckboxComponentDefinition} from '@backstage-components/checkbox';
import {ContainerLayoutDefinition} from '@backstage-components/container-layout';
import {CountdownComponentDefinition} from '@backstage-components/countdown';
import {ImageComponentDefinition} from '@backstage-components/image';
import {LinkComponentDefinition} from '@backstage-components/link';
import {ModalComponentDefinition} from '@backstage-components/modal';
import {PeacockBackgroundComponentDefinition} from '@backstage-components/peacock-background';
import {PhotoboothGalleryComponentDefinition} from '@backstage-components/photobooth-gallery';
import {QRCodeComponentDefinition} from '@backstage-components/qr-code';
import {RadioComponentDefinition} from '@backstage-components/radio';
import {RichTextComponentDefinition} from '@backstage-components/rich-text';
import {RtcRoomContainerComponentDefinition} from '@backstage-components/rtc-room-container';
import {SwitchComponentDefinition} from '@backstage-components/switch';
import {TextInputComponentDefinition} from '@backstage-components/text-input';
import {TitleCardComponentDefinition} from '@backstage-components/title-card';
import {VideoComponentDefinition} from '@backstage-components/video';
import {WbdScreeningBackgroundComponentDefinition} from '@backstage-components/wbd-screening-background';
import {ChatComponentDefinition} from '@backstage-components/chat';
import {GridComponentDefinition} from '@backstage-components/grid';
import {SlideshowComponentDefinition} from '@backstage-components/slideshow';
import type {StackLayoutDefinition} from '@backstage-components/stack-layout';
import {StyleLayoutDefinition} from '@backstage-components/style-layout';
import {RtcRemoteParticipantAudioToggleComponentDefinition} from '@backstage-components/rtc-remote-participant-audio-toggle';

export type KnownComponent = ContentComponent | DynamicLayout;

export type ComponentName = ContentComponent['component'];

export type ContentComponent =
  | ButtonComponentDefinition
  | ChatComponentDefinition
  | CnnQuizComponentDefinition
  | CnnxLandingComponentDefinition
  | CheckboxComponentDefinition
  | CountdownComponentDefinition
  | ImageComponentDefinition
  | LinkComponentDefinition
  | PhotoboothGalleryComponentDefinition
  | GridComponentDefinition
  | QRCodeComponentDefinition
  | RadioComponentDefinition
  | RichTextComponentDefinition
  | SwitchComponentDefinition
  | SlideshowComponentDefinition
  | TextInputComponentDefinition
  | TitleCardComponentDefinition
  | VideoComponentDefinition
  | RtcRemoteParticipantAudioToggleComponentDefinition
  | WbdScreeningBackgroundComponentDefinition;

export type DynamicLayout =
  | StackLayoutDefinition
  | StyleLayoutDefinition
  | CarouselComponentDefinition
  | ContainerLayoutDefinition
  | ModalComponentDefinition
  | PeacockBackgroundComponentDefinition
  | RtcRoomContainerComponentDefinition;

export function isContent(
  definition: DynamicComponent
): definition is ContentComponent {
  return !isLayout(definition);
}

export function isLayout(
  definition: DynamicComponent
): definition is DynamicLayout {
  return (
    definition.component === 'Stacked' ||
    definition.component === 'Style' ||
    definition.component === 'Carousel' ||
    definition.component === 'Container' ||
    definition.component === 'Modal' ||
    definition.component === 'PeacockBackground' ||
    definition.component === 'RtcRoomContainer'
  );
}
