import {LayoutModule, useShowInstructions} from '@backstage-components/base';
import {
  MediaDeviceFailure,
  Kit,
  RoomOptions,
  LocalTrackPublication,
} from '@backstage-components/livekit-client';
import {useSubscription} from 'observable-hooks';
import {css} from '@emotion/react';
import {
  VFC,
  Fragment,
  createContext,
  useContext,
  useMemo,
  useEffect,
  FC,
} from 'react';
import {SchemaType, instructions} from './RtcRoomContainerDefinition';
import {
  useRoomEvents,
  useSpeakerChangeBroadcasts,
} from './RtcRoomContainerEvents';
import {Box} from '@chakra-ui/react';
import {useLiveKitToken} from './use-livekit-token-hook';

export type RtcRoomContainerComponentDefinition = LayoutModule<
  'RtcRoomContainer',
  SchemaType
>;

export const RtcRoomContext = createContext<Kit | undefined>(undefined);
RtcRoomContext.displayName = 'RtcRoomContext';

export const useRtcRoomContext = (): Kit | undefined => {
  const context = useContext(RtcRoomContext);

  return context;
};

export interface RtcRoomProviderProps {
  kit: Kit;
}

export const RtcRoomProvider: FC<RtcRoomProviderProps> = ({kit, ...rest}) => {
  return <RtcRoomContext.Provider value={kit} {...rest} />;
};

export const useRtcRoom = (): Kit | undefined => {
  const kit = useRtcRoomContext();

  return kit;
};

export const RtcRoomContainerComponent: VFC<
  RtcRoomContainerComponentDefinition
> = (definition) => {
  const {slotRenderer: Component = () => <Fragment />, props} = definition;

  const {broadcast, observable} = useShowInstructions(instructions, definition);
  const livekitEndpoint =
    definition.config.scope === 'attendee'
      ? definition.config.livekitEndpoint
      : '';

  const room =
    typeof definition.props.roomChannelName === 'string' &&
    definition.props.roomChannelName !== ''
      ? definition.props.roomChannelName
      : definition.id;
  const token = useLiveKitToken(room);

  const kit = useMemo(
    () => new Kit(livekitEndpoint, token),
    [livekitEndpoint, token]
  );

  useEffect(() => {
    const livekitOptions: RoomOptions = {
      dynacast: true,
      publishDefaults: {
        simulcast: true,
      },
    };

    if (kit !== undefined && !kit.isConnected) {
      kit
        .createRoom(livekitOptions)
        .connect()
        .catch((err) => console.error(err));
    }

    return () => {
      if (kit !== undefined && kit.isConnected) {
        kit.disconnect();
      }
    };
  }, [kit]);

  useRoomEvents(kit, broadcast);
  useSpeakerChangeBroadcasts(broadcast);

  const styles = css`
    ${definition.style}
    ${definition.props.styleAttr}
  `;

  const populateDevices = (): void => {
    Promise.all([
      kit.videoDevices(),
      kit.audioDevices(),
      kit.localVideoTrack?.getDeviceId(),
      kit.localAudioTrack?.getDeviceId(),
    ])
      .then(
        ([
          videoDevices,
          audioDevices,
          activeVideoDeviceId,
          activeAudioDeviceId,
        ]) => {
          broadcast({
            type: 'RtcRoomContainer:on-device-permission-granted',
            meta: {
              audioDevices: audioDevices.map((d) => ({
                kind: d.kind,
                label: d.label,
                deviceId: d.deviceId,
              })),
              videoDevices: videoDevices.map((d) => ({
                kind: d.kind,
                label: d.label,
                deviceId: d.deviceId,
              })),
              activeAudioDeviceId,
              activeVideoDeviceId,
            },
          });
        }
      )
      .catch(() => {
        console.error('Failed to retrieve device information.');
      });
  };

  useSubscription(observable, (inst): void => {
    switch (inst.type) {
      case 'RtcRoomContainer:request-device-permission':
        {
          const devices: Promise<LocalTrackPublication | undefined | void>[] =
            [];

          if (props.requestVideoDevices && props.requestAudioDevices) {
            devices.push(kit.enableCameraAndMic());
          } else {
            if (props.requestVideoDevices) {
              devices.push(kit.enableCamera());
            }

            if (props.requestAudioDevices) {
              devices.push(kit.enableMic());
            }
          }

          Promise.all(devices)
            .then(populateDevices)
            .catch((err) => {
              broadcast({
                type: 'RtcRoomContainer:on-device-permission-denied',
                meta: {
                  deviceError:
                    MediaDeviceFailure.getFailure(err) ||
                    'Unknown Device Error',
                },
              });
            });
        }
        break;
    }
  });

  const renderedChildren = useMemo(() => {
    const {items, ...children} = definition.slots ?? {items: []};
    const components = Object.values(children)
      .flatMap((element) => {
        if (Array.isArray(element)) {
          return element;
        } else if (typeof element !== 'undefined') {
          return [element];
        } else {
          return [];
        }
      })
      .concat(items ?? []);

    return components.map((component) => {
      const componentStyle = `${component.style + ';' || ''}`;
      return (
        <Component
          key={`${component.path.join(':')}:${component.mid}`}
          {...component}
          style={componentStyle}
        />
      );
    });
  }, [Component, definition.slots]);

  if (definition.config.scope === 'admin') {
    return (
      <Box id={definition.id} css={styles}>
        RTC Room
      </Box>
    );
  }

  return <RtcRoomProvider kit={kit}>{renderedChildren}</RtcRoomProvider>;
};
