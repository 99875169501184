import {IUniform, Texture} from 'three';
import {ShaderWithUniforms} from '../../types/shader.types';
import vertexShader from '../ortho.vert.glsl';
import fragmentShader from './grain.frag.glsl';

export type GrainUniforms = {
  tDiffuse: IUniform<Texture | null>;
  amount: IUniform<number>;
  time: IUniform<number>;
};

export const GrainShader: ShaderWithUniforms<GrainUniforms> = {
  name: 'Grain',
  uniforms: {
    tDiffuse: {value: null},
    amount: {value: 0.15},
    time: {value: 0},
  },
  vertexShader,
  fragmentShader,
};
