export {
  RtcRoomContainerComponent,
  RtcRoomProvider,
  useRtcRoomContext,
  useRtcRoom,
} from './RtcRoomContainerComponent';
export type {RtcRoomContainerComponentDefinition} from './RtcRoomContainerComponent';
export {
  defaultFieldData,
  schema,
  uiSchema,
  ComponentDefinition,
  Speaker,
  TrackInfo,
  RemoteParticipant,
} from './RtcRoomContainerDefinition';
export type {SchemaType} from './RtcRoomContainerDefinition';
export {RtcRoomContainerPreviewComponent} from './RtcRoomContainerPreviewComponent';

import {Registry} from '@backstage-components/base';
import {RtcRoomContainerModule} from './RtcRoomContainerModule';
import {ComponentDefinition} from './RtcRoomContainerDefinition';
Registry.register(ComponentDefinition, RtcRoomContainerModule);
