import React, {VFC, useState} from 'react';
import ReactDOM from 'react-dom';
import {ChakraProvider} from '@chakra-ui/react';
import {ApolloProvider} from '@apollo/react-hooks';
import {client} from './apollo';
import App from './App';
import {PageLoader} from './components/PageLoader';
import {useAppHeight} from './hooks';
import './index.css';
// For `@backstage-components/video`
import '@lcdigital/lst-player-ui/dist/css/bitmovinplayer-ui.min.css';
// For `@backstage-components/chat`
import 'stream-chat-react/dist/css/index.css';

const Main: VFC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useAppHeight();

  return (
    <>
      <PageLoader isLoading={isLoading} />
      <App isLoading={isLoading} setIsLoading={setIsLoading} />
    </>
  );
};

ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <ChakraProvider>
        <Main />
      </ChakraProvider>
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById('root')
);
