import {ModuleNode, WithHtmlId} from '@backstage-components/base';
import {VFC} from 'react';
import {SchemaType} from './RtcRoomContainerDefinition';
import {Box} from '@chakra-ui/react';
import {css} from '@emotion/react';

export type RtcRoomContainerProps = SchemaType;

export const RtcRoomContainerPreviewComponent: VFC<
  ModuleNode<'RtcRoomContainer', RtcRoomContainerProps> & WithHtmlId
> = ({module: {props, id}, slotComponent: Slot}) => {
  return (
    <Box
      id={id}
      css={css`
        ${props.styleAttr}
      `}
    >
      <Slot slotName="items" />
    </Box>
  );
};
